.config-row {
  display: flex;
  margin: 10px;
  .config-legend {
    width: 250px;
  }
  .config-input {
    width: 100%;
    display: flex;
    input {
      width: 100%;
    }
  }
}
