.list {
  .source {
    display: flex;
    justify-content: space-between;
    border: 1px solid black;
    padding: 10px;
    margin: 10px;
    box-sizing: border-box;

    .action {
      display: inline-block;
      width: 30px;
      font-size: 20px;
      cursor: pointer;
    }
  }
}
