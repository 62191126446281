.tiles {
  display: flex;
  margin: 0px -20px;
  .tile {
    width: 25%;
    max-width: 250px;
    height: auto;
    margin: 20px;
    background-color: red;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 30px;
    font-weight: bold;

    &:nth-child(1) {
      background-color: #9c27b0;
    }
    &:nth-child(2) {
      background-color: #3f51b5;
    }
    &:nth-child(3) {
      background-color: #8bc34a;
    }
    &:nth-child(4) {
      background-color: #795548;
    }
    &:before {
      content: "";
      display:block;
      padding-top: 100%;
    }
  }
}
