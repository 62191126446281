.outer {
  position: fixed;
  top: 0; bottom: 0;
  left: 0; right: 0;
  padding: 50px;
  box-sizing: border-box;
  border: 1px solid black;
  text-align: center;

  .background {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
    background-color: black;
    opacity: .5;
  }

  .inner {
    background-color: white;
    height: 100%;
    width: 100%;
    z-index: 1000;
    position: relative;
    box-sizing: border-box;
    padding: 20px;
  }
  button {
    margin: 10px;
  }
}
